<template>
  <div>
    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="loading"></BaseLoading>

    <div v-else>
      <BaseHeader :title="'My Writers'"></BaseHeader>

      <div class="card mt-2 border-0  bg-white shadow">
            <div class="card-body text-right d-flex" style="">
              <!-- <form>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Start typing to search">
                  <span class="input-group-append">
                    <button class="btn btn-success" type="search">Search</button>
                  </span>
                </div>
              </form> -->
              <h3>My Writers</h3>
              <!-- <router-link :to="{ name: 'PlaceOrder' }" class="btn btn-primary ml-auto">Order Now</router-link> -->
            </div>

            <div class="table-responsive">
            
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th>#</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Ratings</th>
                    <th>Completed Orders</th>
                    <th class="text-center" style="width: 30px;"><i class="icon-menu-open2"></i></th>
                  </tr>
                </thead>
                <tbody v-if="writers.length > 0">
                  <tr v-for="(writer, index) in writers" :key="index + 'ords'">
                    <td class="text-left">{{ index+1 }}</td>
                    <td class="text-left"> 
                      <!-- <router-link :to="{name: 'writer', params: { id: writer.id }}"></router-link>  -->
                      {{ writer.id || "" }}
                    </td>
                    <td class="text-left">{{ writer.first_name || "" }}</td>
                    <td class="text-left is-title">
                      <p class="is-rating-stars- d-flex mb-1">
                          <span v-for="(i, index) in 5" :key="index + 'rating'">
                              <span class="text-warning icon" :class="writer.rating.average_rating >= index + 1 ? 'icon-star-full2' : 'icon-star-empty3'"></span>
                          </span> 
                          <strong class="ml-2">{{ writer.rating.average_rating || 0 }}</strong>
                      </p>
                      
                    </td>
                    <td class="text-left">{{ writer.rating.completed_orders || "" }}</td>
                    <td class="text-center">
                      <div class="btn-group">
                        <!-- <router-link :to="{name: 'writer', params: { id: writer.id }}" class="btn btn-primary"><i class="icon-eye"></i> View</router-link> -->
                        <!-- <a href="#" class="btn btn-success"><i class="icon-pencil"></i> <span class="d-none d-sm-inline">Reorder</span></a> -->
                        <a data-toggle="modal" data-target="#tipModal" class="btn btn-primary text-ligt"><i class="icon-wallet"></i> <span class="d-none d-sm-inline">Tip</span></a>
                        <!-- <a href="#" class="btn btn-danger"><i class="icon-trash"></i> <span class="d-none d-sm-inline">Blacklist</span></a> -->
                      </div>
                    </td>
                  </tr>
                
                </tbody>
                <tfoot v-else>
                  <tr>
                    <td colspan="11"> <strong>No writers.</strong> </td>
                  </tr>
                </tfoot>
              </table>
                
            </div>
      </div>

    </div>

    <!-- tip writer -->
    <BaseModal :title="'Tip Writer'" :modalId="'tipModal'">
      <TipWriter></TipWriter>
    </BaseModal>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TipWriter from '../../components/payments/modals/TipWriter.vue'

export default {
  name: "MyWriters",
  computed: {
    ...mapState("writers", ["writers", "loading"])
  },
  components: {
    TipWriter
  },

  mounted() {
    this.getWriters()
  },
  methods: {
    ...mapActions("writers", ["getWriters"])
  }
};
</script>
